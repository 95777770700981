import React, { useEffect, useState } from 'react'
import axios from 'axios'


import DateTimePicker from 'react-datetime-picker';
import moment from 'moment'
import 'moment/locale/es';


import exportFromJSON from 'export-from-json'




export default function PedidosRangePage (props) {

    const [pedidos, setPedidos] = useState([])
    const [loading, setLoading] = useState(false)

    const [fechaFrom, setFechaFrom] = useState( 
        moment().startOf('month').toDate()
      )
    const [fechaTo, setFechaTo] = useState( 
        moment().endOf('month').toDate()
      )


      const handleGenerarInforme = async ()=>{

        const data = {
            fechaFrom:moment(fechaFrom).format('YYYY-MM-DD'),
            fechaTo:moment(fechaTo).format('YYYY-MM-DD')
        }
        
        setLoading(true)
        axios.post( "delivery/pedidos/range"  ,data).then((response)=>{
            console.log("response",response);

            const data =  response.data // [{ foo: 'foo'}, { bar: 'bar' }]
            const fileName = `pedidos del ${moment(fechaFrom).format('DD-MM-YYYY')} al ${moment(fechaTo).format('DD-MM-YYYY')}`
            const exportType =  exportFromJSON.types.csv
            
            exportFromJSON({ data, fileName, exportType })

            // setPedidos(response.data.data)
            // window.open(response.data.url);
        }).catch((error)=>{
            console.log("error",error);
        }).finally(()=>{
            setLoading(false)
        })


      }


  return (
    <div className="w-full     flex  justify-center   ">


                <div    className="p-3 flex flex-col gap-5 w-full items-center" >

              <div
                className="p-3 bg-white  rounded-lg  w-full md:w-1/3 lg:w-1/4 "
              >

                        <div className="flex flex-col gap-3 ">
                        
                        
                                <div className="w-full flex flex-col">
                                    <p className="text-xs">Desde:</p>
                                    <DateTimePicker
                                        locale="es"
                                        
                                        format="d/MM/y"
                                        clearIcon={null}
                                        onChange={
                                            (fecha)=>setFechaFrom(fecha)
                                        }
                                        value={  moment(fechaFrom).toDate()  }
                                        />
                                </div>

                                <div className="w-full flex flex-col">
                                    <p className="text-xs">Hasta:</p>
                                    <DateTimePicker
                                    locale="es"
                                    
                                    format="d/MM/y"
                                    clearIcon={null}
                                    onChange={
                                        (fecha)=>setFechaTo(fecha)
                                    }
                                    value={  moment(fechaTo).toDate()  }
                                    />
                                </div>

                                <button
                                disabled = {loading}
                                onClick = {  handleGenerarInforme }
                                    className="  disabled:bg-gray-500  bg-blue-300   text-white font-bold py-2 px-4 rounded"
                                >
                                    {
                                        loading?'Aguarde...':'Generar Informe'
                                    }
                                </button>

                            </div>

              </div>

              {/* <div
                className="p-3 bg-white  rounded-lg w-full"
              >
            
                    <table
                    className="w-full"
                    >
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Cliente</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pedidos.map(i=>
                                    <tr key={i._id}>
                                <td>2021-01-01</td>
                                <td>Cliente 1</td>
                                <td>1000</td>
                            </tr>
                                )
                            }

                        </tbody>
                    </table>


                </div> */}
     

    </div>
    </div>
  )
}
